<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <!--<el-form-item label="发放日期" prop="authUserName">-->
                    <!--<el-date-picker-->
                            <!--clearable-->
                            <!--style="width: 220px;"-->
                            <!--v-model="selectDate"-->
                            <!--type="daterange"-->
                            <!--format="yyyy 年 MM 月 dd 日"-->
                            <!--value-format="yyyy-MM-dd"-->
                            <!--@change="selectedDate"-->
                            <!--range-separator="至"-->
                            <!--start-placeholder="开始日期"-->
                            <!--end-placeholder="结束日期"-->
                    <!--&gt;</el-date-picker>-->
                <!--</el-form-item>-->
                <!--<el-form-item style="margin-top: auto;">-->
                    <!--<div class="fill-btn" @click="goodsList">查询</div>-->

                <!--</el-form-item>-->


                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>
            </el-form>
        </div>

        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="price" label="充值金额" min-width="20%"></el-table-column>
                <el-table-column prop="giving" label="赠送金额" min-width="20%"></el-table-column>
                <el-table-column prop="get_coupon" label="赠送优惠券" min-width="20%">
					<template slot-scope="{row}">
						{{row.get_coupon&&row.get_coupon.note}}
					</template>	 
				</el-table-column>
				<el-table-column prop="num" label="赠送优惠券数量" width="130"></el-table-column>
				<el-table-column prop="intro" label="赠送描述" width="130" show-overflow-tooltip></el-table-column>
                <el-table-column prop="created_at" label="创建日期" width="148"></el-table-column>
                <el-table-column width="120" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handEditShow(row)"></el-button>
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini"
                                       @click="delRed(row)"></el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog :title="`${editId?'编辑':'新增'}`" width="600px" :visible.sync="showAdd">
            <div style="padding: 20px;">
                <el-form ref="form" :rules="getRules" :model="addCourse" label-width="100px">
                    <el-form-item label="充值金额" prop="price">
                        <el-input   type="number" v-model="addCourse.price"></el-input>
                    </el-form-item>
                    <el-form-item label="赠送金额">
                        <el-input type="number" v-model="addCourse.giving"></el-input>
                    </el-form-item>
					<el-form-item label="赠送优惠券">
					    <el-select v-model="addCourse.coupon_id" clearable placeholder="请选择赠送优惠券">
					    	<el-option v-for="item in couponList" :key="item.id" :label="item.note"
					    		:value="item.id">
					    	</el-option>
					    </el-select>
					</el-form-item>
					<!-- coupon_id  优惠券   num  数量 -->
					<el-form-item v-if="addCourse.coupon_id" prop="num" label="优惠券数量">
                        <el-input type="number" v-model="addCourse.num"></el-input>
                    </el-form-item>
					<el-form-item prop="intro" label="赠送描述">
                        <el-input v-model="addCourse.intro"></el-input>
                    </el-form-item>
                    <el-form-item>
						<el-button type="primary" @click="goodsSave" v-if="editId" style="margin: 10px auto">保存</el-button>
						<el-button type="primary" @click="redCreate" v-else style="margin: 10px auto">保存</el-button>
					</el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {delToast, formVerify} from './../../utlis/decorators'
    import RichText from './../../components/RichText'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        components: {
            RichText
        },
        data() {
            return {
                selectDate: [],
                dialogFormVisible: false,
                loading: false,
                currentTop: 0,
                total: 0,
                allPrice: 0,
               
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 10,
                    username: ''
                },
                form: {
                    nickname: ''
                },
                editForm: {
                    role_ids: [],
                    nickname: '',
                    username: '',
                    password: '',
                    status: ''
                },
                currentId: '',
                tableData: [],
                typeList: [],
                
                teacherAll: [],
				
				
				addCourse: {
					price:'',
					giving:'',
					coupon_id:'',
					num:'',
					intro:''
				},
				showAdd: false,
				editId:'',
            }
        },
        created() {
            this.goodsList();
            this.couponDate();
        },
        computed: {
            getRules() {
                return {
                    price: [{required: true, message: `充值金额不能为空`, trigger: ['blur', 'change']}],
                    num: [{required: true, message: `赠送优惠券数量不能为空`, trigger: ['blur', 'change']}],
                    intro: [{required: true, message: `赠送优惠券描述不能为空`, trigger: ['blur', 'change']}],
                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.getOrderCourse();
            },
        },
        methods: {
            handShowAdd() {
				this.editId = '';
                try {
                	this.showAdd = true;
                	this.$refs['form'].resetFields()
                	for (let i in this.addCourse) {
                		this.addCourse[i] = '';
                	}
                } catch {}
            },
			handEditShow(row) {
				this.editId = row.id;
				for (let i in this.addCourse) {
					if(i=='intro'){
						this.addCourse[i] = row[i];
					}else{
						this.addCourse[i] = Number(row[i])||'';
					}
				}
			    this.showAdd = true;
			},
            // 选择日期
            selectedDate(e) {
                if (e) {
                    this.searchForm.start_time = e[0];
                    this.searchForm.end_time = e[1];
                    console.log(this.searchForm);
                } else {
                    this.searchForm.start_time = '';
                    this.searchForm.end_time = '';
                }
            },
            
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            //获取
            async goodsList() {
                let [e, data] = await this.$api.goodsList(this.searchForm);
                if (e) return;
                if (data.code === 200) {
                    console.log(data.data);
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                    // this.allPrice = data.price
                }
            },
			//获取
            async couponDate() {
                let [e, data] = await this.$api.couponDate();
                if (e) return;
                if (data.code === 200) {
                    this.couponList = data.data;
                }
            },
            //添加
            @formVerify('form')
            async redCreate() {
				let params = {};
				for (let i in this.addCourse) {
					params[i] = this.addCourse[i]||0;
				}
                let [e, data] = await this.$api.goodsCreate(params);
                if (e) return;
                if (data.code === 200) {
                    this.$message.success(data.msg)
                    this.goodsList();
                    this.showAdd = false;
                }
            },
			//编辑
			@formVerify('form')
            async goodsSave() {
				let params = {id:this.editId};
				for (let i in this.addCourse) {
					params[i] = this.addCourse[i]||0;
				}
                let [e, data] = await this.$api.goodsSave(params);
                if (e) return;
                if (data.code === 200) {

                    this.$message.success(data.msg)
                    this.goodsList();
                    this.showAdd = false;
                }
            },
            @delToast()
            async delRed({id}) {
                let [e, data] = await this.$api.goodsDel({id});
                if (e) return;
                if (data.code === 200) {
                    this.goodsList();
                    this.$message.success('删除成功')

                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
            //导出
            async excelCourseVip() {
                this.$downFile('admin/theme/order/excel', this.searchForm, '课程购买列表');
            },

        }
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
